import React from 'react';
import _ from 'lodash';
import { getLocalizedText } from './common/localizationManager';

//const Notifications = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
//const Scheduler = React.lazy(() => import('./views/Routes/Management/Scheduler/Scheduler'));
const AuthTemplates = React.lazy(() => import('./views/Routes/Management/AuthTemplates/AuthTemplates'));
const Dashboard = React.lazy(() => import('./views/Routes/Management/Dashboard/Dashboard'));
const Tenant = React.lazy(() => import('./views/Routes/Management/Tenant/Tenant'));
const Company_Mng = React.lazy(() => import('./views/Routes/Management/Companies/Company'));
const Users_Mng = React.lazy(() => import('./views/Routes/Management/Users/Users'));
const ResourceDefinition_Mng = React.lazy(() => import('./views/Routes/Management/ResourceDefinition/ResourceDefinition'));
const Parameters_Mng = React.lazy(() => import('./views/Routes/Management/Parameters/Parameters'));
const Kibana = React.lazy(() => import('./views/Routes/Management/Kibana/Kibana'));
const Department = React.lazy(() => import('./views/Routes/Management/Department/Department'));
const CommunicationDefinitions_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationDefinitions/CommunicationDefinitions'));
const CommunicationTemplates_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationTemplates/CommunicationTemplates'));
const Notifications_Mng = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
const LivePreview = React.lazy(() => import('./views/Routes/LivePreview/LivePreview'));
const TransactionLogs_Mng = React.lazy(() => import('./views/Routes/Management/TransactionLogs/TransactionLogs'));
const ChangePassword = React.lazy(() => import('./views/Routes/CommonPages/ChangePassword/ChangePassword'));
const File = React.lazy(() => import('./views/Routes/File/File'));
const MetaData = React.lazy(() => import('./views/Routes/MetaData/MetaData'));

var PageInfos = {
    Dashboard: {
        name: getLocalizedText("DASHBOARD"),
        url: `/dashboard`,
        component: Dashboard,
        resourceCode: 'MainPage',
        icon: 'icon-speedometer',
    },
    Title_Admin: {
        title: true,
        name: getLocalizedText("TITLE_ADMIN"),
    },
    Menu_Admin: {
        name: getLocalizedText("ADMINISTRATION"),
        resourceCode: 'AdminPages',
        icon: 'cui-cog',
        defaultOpen: true
    },
    Tenant: {
        name: getLocalizedText('TENANTS'),
        url: '/tenant',
        component: Tenant,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Tenant_Res',
        icon: 'icon-book-open'
    },
    Company_Mng: {
        name: getLocalizedText("COMPANIES"),
        url: '/company',
        component: Company_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreCompany_Res',
        icon: 'icon-grid',
    },
    Department: {
        name: getLocalizedText("DEPARTMENTS_TITLE"),
        url: '/department',
        component: Department,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreDepartment_Res',
        icon: 'icon-puzzle',
    },
    AuthTemplate_Mng: {
        name: getLocalizedText("AUTH_TEMPLATES"),
        url: '/authTemplates',
        component: AuthTemplates,
        parentResourceCode: 'AdminPages',
        resourceCode: 'AuthTemplates',
        icon: 'icon-docs',
    },
    Users_Mng: {
        name: getLocalizedText("USERS"),
        url: '/users',
        component: Users_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Users',
        icon: 'icon-people',
    },
    ResourceDefinition_Mng: {
        name: getLocalizedText("RESOURCE_DEFINITION"),
        url: '/resource-definitions',
        component: ResourceDefinition_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'ResourceDefinition',
        icon: 'icon-layers',
    },
    Parameters_Mng: {
        name: getLocalizedText("PARAMETERS"),
        url: '/parameters',
        component: Parameters_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'SystemParameters',
        icon: 'icon-list',
    },
    Menu_Communications: {
        name: getLocalizedText('COMMUNICATION_SETTINGS_LABEL'),
        resourceCode: 'Communication',
        parentResourceCode: 'AdminPages',
        icon: 'icon-feed',
    },
    CommunicationDefinitions_Mng: {
        name: getLocalizedText('COMMUNICATION_DEFINITIONS'),
        url: '/communicationDefinitions',
        component: CommunicationDefinitions_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationDefinitions_tab',
        icon: 'icon-note',
    },
    CommunicationTemplates_Mng: {
        name: getLocalizedText('COMMUNICATION_TEMPLATES'),
        url: '/communicationTemplates',
        component: CommunicationTemplates_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationTemplates_tab',
        icon: 'icon-envelope',
    },
    SearchMonitor_Mng: {
        name: getLocalizedText('SEARCH_MONITOR_LABEL'),
        url: '/kibana',
        component: Kibana,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreSearchMonitor_Res',
        icon: 'icon-magnifier',
    },
    AuditLogs_Mng: {
        name: getLocalizedText('AUDIT_LOGS'),
        url: '/transactionLogs',
        component: TransactionLogs_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'TransactionLogs_Res',
        icon: 'icon-book-open',
    },
    Notifications: {
        name: getLocalizedText("NOTIFICATIONS"),
        url: '/notifications/:id?',
        component: Notifications_Mng,
        resourceCode: 'Notifications',
        parentResourceCode: 'AdminPages',
        icon: 'icon-badge',
        showInSidebar: false,
    },
    LivePreview: {
        name: getLocalizedText("LIVE_PREVIEW_LABEL"),
        url: '/livePreview',
        component: LivePreview,
        resourceCode: 'TestResource',
        icon: 'icon-eye',
        badge: { variant: 'info', text: 'NEW' }
    },
    ChangePassword: {
        name: getLocalizedText('CHANGE_PASSWORD_LABEL'),
        url: '/changePassword',
        component: ChangePassword,
        resourceCode: 'ChangePassword_Res',
        showInSidebar: false,
    },    File: {
        name: getLocalizedText('File'),
        url: '/file',
        component: File,
        resourceCode: 'File_Res',
        icon: 'icon-energy',
    },
    MetaData: {
        name: getLocalizedText('Meta Data'),
        url: '/metaData',
        component: MetaData,
        resourceCode: 'MetaData_Res',
        icon: 'icon-energy',
    }
};

// Scheduler: {
//     name: "Scheduler",
//     url: '/scheduler',
//     component: Scheduler,
//     resourceCode: '',
//     icon: 'icon-clock',
// },

export var Pages = function () {
    for (var page in PageInfos) {
        PageInfos[page].key = page;

        if (PageInfos[page].url && PageInfos[page].path == null)
            PageInfos[page].path = `${PageInfos[page].url}`;
    }
    // let sortedPagesByName = Object.values(PageInfos).sort((a, b) =>
    // (a.name === 'Dashboard' || b.name === 'Dashboard') || a.name.localeCompare(b.name));
    return PageInfos;
}();

export var PagesAsNav = function () {
    var pages = _.values(_.cloneDeep(Pages)).filter(page => page.showInSidebar !== false);
    var toBeDeleted = [];
    for (var page of pages) {
        if (page.defaultOpen) {
            delete page.defaultOpen
            if (!page.url)
                page.url = '';
        }
        if (page.parentResourceCode) {

            var foundPage = pages.find(x => page.parentResourceCode && x.resourceCode == page.parentResourceCode);
            if (foundPage) {
                if (foundPage.children == null)
                    foundPage.children = [];

                foundPage.children.push(page);
                toBeDeleted.push(pages.findIndex(x => x.key == page.key));
            }
        }
    }

    toBeDeleted.sort((a, b) => a - b);
    toBeDeleted.reverse();

    for (var ind of toBeDeleted) {
        pages.splice(ind, 1);
    }
    return pages;
}();

export var PagesAsRoute = function () {
    var pages = _.values(_.cloneDeep(Pages));

    for (var page of pages) {
        if (page.url && page.parentResourceCode == null)
            page.exact = true;
    }
    return pages;
}();