import _ from "lodash";
import { getPreferredLanguage } from "./localizationManager";
import { LabelPositions, ComponentType, Tables, IToggleConfig, IExportConfig, ILabelProps, ICustomParameterFormat, IPrimaryIdEmptinessComparers } from "./typeConfig";
import { Regexes } from "./validator";

export const IdentityURL = process.env.REACT_APP_IDENTITY_URL;
export const AdminURL = process.env.REACT_APP_ADMIN_URL;
export const ApiURL = process.env.REACT_APP_API_URL;
export const ApiURL_2 = process.env.REACT_APP_API2_URL;
export const ApiURL_3 = process.env.REACT_APP_API3_URL;
export const KibanaURL = process.env.REACT_APP_KIBANA_URL;
export const SchedulerURL = process.env.REACT_APP_SCHEDULER_URL;
export const ECommerceURL = process.env.REACT_APP_ECOMMERCE_URL;
export const VAULT_TOKEN = "hvs.wpL7gek9IE29SvRpWHqvB9Qi";
export const VAULT_URL = "https://test-bytesec.icredible.com/vault";

export const DEFAULT_CACHE_EXPIRATION_MIN = 5;
export const DEFAULT_CACHE_LOCK_TIMEOUT_MS = 5000;
export const DEFAULT_CACHE_STORAGE = "local";

export const DEFAULT_LABEL_POSITION = LabelPositions.LEFT_OF_INPUT;
export const DEFAULT_TABLE_CHOICE = Tables.POWER_GRID;
export const DEFAULT_COMPONENT_TYPE = ComponentType.FORM_CONTROL;
export const DEFAULT_GRID_BLOCK_SIZE = 100;

export const IsRTL = _.get(getPreferredLanguage(), 'isRTL', false);

export const ERROR_MESSAGE_COLUMN_NAME = "errorMessage";

export const DEFAULT_TOGGLE_CONFIG: IToggleConfig = {
    ON: 1,
    OFF: 0
}

export const DEFAULT_EXPORT_CONFIG: IExportConfig = {
    excelConfig: {
        exportable: true,
        importable: true,
        label: null,
        order: 999,
    }
}

export const CHANGE_PASSWORD_URL = '/changePassword';

export const DEFAULT_LANGUAGE_CODE = "en";

export const DEFAULT_LABEL_PROPS: ILabelProps = {
    position: DEFAULT_LABEL_POSITION,
}

//#region Enums

export enum UserStatus {
    DELETED = -1,
    WAITING_FOR_APPROVAL,
    STILL_WORKS,
    QUITTED,
    WAITING_FOR_VERIFICATION
}

export enum ThemeColors {
    SUCCESS = "#4DBD74",
    WARNING = "#F9B115",
    DANGER = "#E6471E",
}

//#endregion Enums

export const WORKING_STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "-1": ThemeColors.DANGER,
    "0": ThemeColors.WARNING,
    "1": ThemeColors.SUCCESS,
    "2": ThemeColors.DANGER,
    "3": ThemeColors.WARNING
}

export const STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "-1": ThemeColors.DANGER,
    "1": ThemeColors.SUCCESS,
}

export const TOGGLE_CUSTOM_FORMAT: ICustomParameterFormat = {
    "true": ThemeColors.SUCCESS,
}

export const PRIMARY_ID_EMPTINESS_COMPARERS: IPrimaryIdEmptinessComparers = {
    FALSY: (id, idStr) => !id,
    EMPTY_GUID: (id, idStr) => Regexes.emptyGuid.test(idStr)
}

export default {
    AdminURL, IdentityURL, ApiURL, ApiURL_2, ApiURL_3, KibanaURL, SchedulerURL, ECommerceURL,
    DEFAULT_LABEL_POSITION, DEFAULT_CACHE_EXPIRATION_MIN, DEFAULT_CACHE_LOCK_TIMEOUT_MS,
    DEFAULT_CACHE_STORAGE, DEFAULT_TABLE_CHOICE, DEFAULT_COMPONENT_TYPE, IsRTL,
    DEFAULT_GRID_BLOCK_SIZE, DEFAULT_TOGGLE_CONFIG, DEFAULT_EXPORT_CONFIG, CHANGE_PASSWORD_URL, UserStatus, DEFAULT_LABEL_PROPS,
    WORKING_STATUS_CUSTOM_FORMAT, STATUS_CUSTOM_FORMAT, TOGGLE_CUSTOM_FORMAT, ERROR_MESSAGE_COLUMN_NAME,
    PRIMARY_ID_EMPTINESS_COMPARERS, VAULT_TOKEN, VAULT_URL
}